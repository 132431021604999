// Third party libraries
@import '~rc-slider/assets/index';
.rc-slider-tooltip-custom {
  .rc-slider-tooltip-inner {
    color: inherit;
    background-color: transparent;
    box-shadow: none;
    padding-top: 20px;
  }
  .rc-slider-tooltip-arrow {
    display: none;
  }
}
.rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
.rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
  animation-name: none;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
  animation-name: none;
  animation-play-state: running;
}
.rc-slider-tooltip-zoom-down-enter,
.rc-slider-tooltip-zoom-down-appear {
  transform: none;
}
.rc-slider-tooltip-zoom-down-leave {
  transform: none;
}
@keyframes rcSliderTooltipZoomDownIn {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes rcSliderTooltipZoomDownOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

//Highcharts override
.highcharts-credits {
  display: none !important;
}
.highcharts-loading {
  background-color: transparent !important;
}
.highcharts-background {
  background-color: transparent !important;
}
.highcharts-plot-background {
  background-color: transparent !important;
}
.highcharts-tooltip-container {
  z-index: 99999 !important;
}

//Leaflet override
.leaflet-tooltip {
  padding: 2px 5px 0px 5px !important;
  font-size: 12px !important;
  border-radius: 0 !important;
}

.leaflet-tooltip.noContainer {
  font-weight: bold;
  color: #ffffff !important;
  padding: 0px !important;
  text-shadow: 1px 1px 1px black;

  background: none !important;
  border: none !important;
  box-shadow: none !important;
}
.leaflet-tooltip.noContainer:before {
  border: none !important;
  content: '\2022' !important;
  font-size: 15px !important;
}
.leaflet-tooltip-left.noContainer:before {
  margin-right: -11px !important;
}
.leaflet-tooltip-right.noContainer:before {
  margin-left: -11px !important;
}
.leaflet-tooltip-bottom.noContainer:before,
.leaflet-tooltip-top.noContainer:before {
  left: 0 !important;
  margin-left: 0px !important;
}
.leaflet-tooltip-left.noContainer:before,
.leaflet-tooltip-right.noContainer:before {
  top: 0 !important;
  margin-top: 0px !important;
}

.leaflet-container {
  font-family: inherit;
}
// needed to allow leaflet button style without actual anks
.leaflet-bar.ank {
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  width: 26px;
  height: 26px;
  line-height: 26px;
  display: block;
  text-align: center;
  text-decoration: none;
  color: black;
}
.leaflet-bar.ank:hover {
  background-color: #f4f4f4;
}

/*
  osm black and white layer workaround due to not being maintained anymore
  for now use OpenStreetMap tile api instead of https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png and remove saturation
  for updates see https://phabricator.wikimedia.org/T187601
*/
.blackAndWhiteFilter {
  filter: saturate(0);
}

// Helper classes
.bg-image-center {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.bg-backdrop,
.bg-zone {
  cursor: grab !important;
}

/* MathML related styling */
/* unfortunatly chrome does not support MathML */
/* math */
math {
  display: inline;
  text-indent: 0;
}
math[display='block'] {
  display: block;
  text-align: center;
}

/* fraction */
mfrac {
  display: inline-table;
  vertical-align: middle;
  border-collapse: collapse;
}
mfrac > * {
  display: table-row;
}
mfrac:not([linethickness='0']) > *:first-child {
  border-bottom: solid none;
}

/* token elements */
mspace {
  margin: 0.2em;
}
mi {
  font-style: italic;
}
mo {
  margin-right: 0.2em;
  margin-left: 0.2em;
}
/* end of MathML related styling */

.white-space {
  &-nowrap {
    white-space: nowrap;
  }
  &-break {
    white-space: break-spaces;
  }
}

.gap {
  &-rem {
    &-0 {
      &-2 {
        gap: 0.2rem;
      }
      &-5 {
        gap: 0.5rem;
      }
    }
  }
}

.grid {
  &-cols {
    &-2 {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.d {
  &-grid {
    display: grid;
  }
  &-flex {
    display: flex;
  }
}

.developer {
  &-highcharts-item-hidden {
    color: #cccccc !important;
    & .chart-item-indicator {
      background-color: rgb(204, 204, 204) !important;
    }
  }
}
